import PostCard from '../components/PostCard';
import { useState, useEffect } from "react"
import { getPosts } from '../services';
import { useParams } from 'react-router-dom';
import MetaDecorator from '../components/MetaDecorator'
import { MetaDataList } from "../components/Data";

export default function Blog() {
  const [posts, setPosts] = useState([])
  const [alias, setAlias] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const { category } = useParams()

  useEffect(() => {
    getPosts(category).then((newPosts) => {
      setPosts(newPosts);
      setAlias(newPosts[0].categories.title);
      setDescription(newPosts[0].categories.description);
      setTitle(newPosts[0].categories.title);
    });

  }, [category]);

  return (
    <>
      <MetaDecorator title={MetaDataList.blog[category].title} description={MetaDataList.blog[category].description} keyword={MetaDataList.blog[category].keyword} canonical={MetaDataList.blog[category].canonical}/>
      <div className="relative z-20 dark:bg-gray-900 pt-4 max-w-7xl mx-auto px-4 sm:px-6 min-h-[480px] md:min-h-[680px]">
        <div className="flex flex-wrap mx-4">
          {posts.map((post, index) => (
            <PostCard key={index} post={post} />
          ))}
        </div>
      </div>
    </>
  )
}
