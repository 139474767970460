import BlockContent from "@sanity/block-content-to-react"
import { useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { getPostDetail } from '../services';
import Categories from '../components/Categories';
import PostWidget from '../components/PostWidget';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import MetaDecorator from '../components/MetaDecorator'
import { MetaDataList } from "../components/Data";

export default function BlogDetail() {

  const [postDetail, setPostDetail] = useState([])
  const [postDetailMenu, setPostDetailMenu] = useState([])
  const [articleTitle, setArticleTitle] = useState('')
  const [description, setArticleDescription] = useState('')
  const [categoryTitle, setCategoryTitle] = useState('index')
  const [categoryAlias, setCategoryAlias] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const { slug } = useParams()

  var menu = []
  const serializers = {
    types: {
      block(props) {
        switch (props.node.style) {
          case 'p':
            return <p className="text-base text-black leading-loose"></p>
          case 'h2':
            return <h2 id={props.children} className="scroll-mt-24 text-2xl font-extrabold text-blue-900 my-6">{props.children}</h2>
          default:
            return <p>{props.children}<br /></p>
        }
      },
    }
  }

  useEffect(() => {
    getPostDetail(slug).then((newPost) => {
      setPostDetail(newPost[0]);
      setCategoryTitle(newPost[0].categories[0].title);
      setCategoryAlias(newPost[0].categories[0].title);
      setArticleTitle(newPost[0].title)
      setArticleDescription(newPost[0].body[0].children[0].text)

      const postBody = newPost[0].body
      {
        postBody.map((item, index) => (
          item.style == 'h2' ? menu[index] = item.children[0].text : ''
        ))
      }
      setPostDetailMenu(menu)
      setIsLoading(false)
    });
  }, []);

  const level1 = { name: '計劃一覽', href: '/blog', }
  const level2 = { name: categoryTitle, href: "/blog/" + categoryAlias }

  return (
    <>
      <MetaDecorator title={articleTitle} description={description} keyword={MetaDataList.blog[categoryTitle].keyword} canonical={`https://www.drquickpayhk.com/blog/${categoryTitle}/${articleTitle}`}/>

      <div className="px-4 mx-auto w-full container flex">

        {/*<Sidebar menu={postDetailMenu} />*/}
        <main className="flex-auto w-full min-w-0 lg:static lg:max-h-full lg:overflow-visible">
          <div className="block lg:flex w-full">
            <div className="flex-auto pt-6 min-w-0  lg:px-8 lg:pt-10 pb:12 xl:pb-24 lg:pb-16">
              {isLoading ? (
                <h1 className="uppercase font-bold text-3xl tracking-wide mb-5 md:text-6xl lg:text-4xl flex items-center mt-16 justify-center">
                  載入文章中...
                </h1>
              ) : (
                <div>

                  <div className="block md:flex md:justify-between">
                    <Breadcrumb level1={level1} level2={level2} />
                  </div>
                  <div className="pb-2 mb-4 border-b border-gray-200 dark:border-gray-800">
                    <h1 className="inline-block text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white" id="content">{postDetail.title}</h1>
                  </div>
                  <div className="">
                    {postDetail.mainImage && postDetail.mainImage.asset && (
                      <img
                        src={postDetail.mainImage.asset.url}
                        alt={postDetail.title}
                        title={postDetail.title}
                        className="my-2"
                      />
                    )}
                    <BlockContent
                      blocks={postDetail.body}
                      projectId="49v0y7a6"
                      dataset="production"
                      serializers={serializers}
                      className="break-words leading-10 text-lg"
                    />
                  </div>
                </div>

              )}
            </div>
            {/*
            <div className="flex-none z-20 pl-0 mr-8 md:mr-2 w-full lg:w-72 lg:w-80 xl:text-sm">
              <div className="lg:sticky relative top-24">
                        <PostWidget />
                <Categories />
              </div>
            </div>
            */}
          </div>
        </main>
      </div>
    </>
  )
}
