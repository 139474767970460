import React from 'react'
import FeaturedPosts from '../components/FeaturedPosts'
import Slider from '../components/Carousel/Slider'
import MetaDecorator from '../components/MetaDecorator'
import logo from '../images/logo.png'
import index1 from '../images/001.webp'
import index2 from '../images/002.webp'
import index3 from '../images/003.webp'
import index4 from '../images/004.webp'
import index5 from '../images/005.webp'
import index6 from '../images/006.webp'
import { MetaDataList } from "../components/Data";


export default function Home() {
    return (
        <>
            <MetaDecorator title={MetaDataList.home.title} description={MetaDataList.home.description} keyword={MetaDataList.home.keyword} page={MetaDataList.home.page} canonical={MetaDataList.home.canonical} />
            <section>
                <div><img alt='drquickpayhk' src={logo} className='absolute z-10 top-48 left-24 hidden lg:block' /></div>
                <Slider />
            </section>
            <section id="about-us" className="bg-white bg-no-repeat bg-cover bg-scroll bg-[50%_0] py-[70px]">
                <h2 className='mb-[40px] text-center wow text-2xl md:text-4xl fadeIn text-theme-color-1 underline-offset-8 underline'>「資料絕對保密，解決財務困難」
                </h2>
                <div className='section-container mx-auto px-[30px]'>
                    <div className=' flex-col-reverse md:flex-row flex items-center justify-between'>
                        <div className='flex-1 text-center md:text-left'>
                            <h2 className='text-[40px] mb-[20px] wow fadeInLeft'><span className='font-bold text-theme-color-4 '>專業</span> 清數</h2>
                            <div className='wow fadeInLeft'>
                                <p className='text-2xl mb-4'>快清數博士 Dr.Credit 我地專業團隊，有豐富財務經驗，為所有一線二線客户，解決財務上困難。提供一線二線攬數計劃，我們保證資料絕對保密。</p>

                                <p className='text-2xl mb-4'>明白到每一位客人有個別情況同需求，會因應不同情況而替客人提供適合的方案，務求能替每一位客人解決財政上之困境。快清數博士 Dr.Credit 積極拓展一線二線清數市場，攬數等各類清數計劃，為客戶提供專業的財務分析，度身訂造合適的債務整合方案以供選擇。客戶只需提供簡單資料，30分鐘即可獲得初步批核。我們的清數服務多元化，可全面解決各種個人需要，提升TU，無論在任何時候遇到任何困難，都可幫到手。快捷批核，一個簡單、快捷的申請程序意味著你可以在最短30分鐘內解決財政問題。</p>
                                <p className='text-2xl'>快清數博士Dr.Credit 為客戶提供一個可靠及專業的服務，利息為你慳到盡，以顧客本身的還款能力來控制所有貸款的支出，當中可以作出靈活調整，以適應您的個人情況來計劃還款。</p>
                                <br />
                            </div>

                        </div>

                    </div>
                </div>
            </section>
            <p className='text-center text-2xl md:text-4xl text-theme-color-5 font-bold italic'>借錢梗要還，咪俾錢中介</p>

            <section id="promise" className='bg-white'>
                <div className='section-container mx-auto '>
                    <div className='mt-[50px] grid md:grid-cols-3 gap-[40px] wow bounceIn'>

                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='mb-2 bg-white w-full flex justify-center items-center'>
                                <a className='w-full h-full' href="/blog"><img alt="一線大額清數計劃" src={index1} className='w-full h-full object-cover' /></a>
                            </div>
                            <a href="/blog"><h3 className='text-theme-color-4 text-3xl  mb-[30px]'>一線大額清數計劃</h3></a>
                            <p className='text-black text-2xl leading-7'>幫你集中清還所有卡數和外債 大大減低利息支出，輕鬆還款，提升TU。</p>
                        </div>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='mb-2 bg-white w-full flex justify-center items-center'>
                                <a className='w-full h-full' href="/blog"><img alt="二線財務清數救星" src={index2} className='w-full h-full object-cover' /></a>
                            </div>
                            <a href="/blog"><h3 className='text-theme-color-4 text-3xl  mb-[30px]'>二線財務清數救星</h3></a>
                            <p className='text-black text-2xl leading-7'>二線攬數，清數救星，整合所有二線財務供款、外債，大大減低供款壓力減少支出。</p>
                        </div>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='mb-2 bg-white w-full flex justify-center items-center'>
                                <a className='w-full h-full' href="/blog"><img alt="物業按揭" src={index3} className='w-full h-full object-cover' /></a>
                            </div>
                            <a href="/blog"><h3 className='text-theme-color-4 text-3xl  mb-[30px]'>物業按揭</h3></a>
                            <p className='text-black text-2xl leading-7'>可按揭物業，包括私樓、居屋、唐樓、村屋、商廈、工廈等，高成數按揭。</p>
                        </div>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='mb-2 bg-white w-full flex justify-center items-center'>
                                <a className='w-full h-full' href="/blog"><img alt="生意現金周轉" src={index4} className='w-full h-full object-cover' /></a>
                            </div>
                            <a href="/blog"><h3 className='text-theme-color-4 text-3xl  mb-[30px]'>生意現金周轉</h3></a>
                            <p className='text-black text-2xl leading-7'>為大小企業而設 令您的生意能夠靈活周轉</p>
                        </div>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='mb-2 bg-white w-full flex justify-center items-center'>
                                <a className='w-full h-full' href="/blog"><img alt="私人現金周轉" src={index5} className='w-full h-full object-cover' /></a>
                            </div>
                            <a href="/blog"><h3 className='text-theme-color-4 text-3xl  mb-[30px]'>私人現金周轉</h3></a>
                            <p className='text-black text-2xl leading-7'>超低優惠貸款利率，與你共同度過困難時刻</p>
                        </div>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='mb-2 bg-white w-full flex justify-center items-center'>
                                <a className='w-full h-full' href="/blog"> <img alt="買樓置業現金周轉" src={index6} className='w-full h-full object-cover' /></a>
                            </div>
                            <a href="/blog"><h3 className='text-theme-color-4 text-3xl  mb-[30px]'>買樓置業現金周轉</h3></a>
                            <p className='text-black text-2xl leading-7'>臨時提供大量資金，協助順利完成樓宇交易</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="case" className='py-[70px]'>
                <FeaturedPosts />
            </section>


        </>

    )
}
