import { getLatestPosts } from "../services";
import { useState, useEffect } from "react"
import moment from 'moment';

export default function FeaturedPosts() {
   const [recentPosts, setrecentPosts] = useState([])

   useEffect(() => {
      getLatestPosts(9, true).then((latestPosts) => {
        setrecentPosts(latestPosts)
      });
    }, [])

  return (
<section className="pb-4 sm:pb-16">

   <div className="container m-auto">
      <div className="flex flex-wrap mx-4">
         {recentPosts.map((post) => (
            <div key={post.title} className="mx-4 md:mx-0 w-full md:w-1/2 lg:w-1/3 px-4">
               <div className="max-w-[370px] mx-auto mb-10">
                  {post.mainImage && post.mainImage.asset && (
                     <a className='block rounded overflow-hidden mb-8 h-full md:h-60'href={`/blog/${post.categories.title}/${post.title}`}>
                    <img
                      src={post.mainImage.asset.url}
                      alt={post.title}
                      title={post.title}
                     className="block rounded overflow-hidden mb-8 h-full object-cover"
                      />
                     </a>
                  )}
               <div>
                  <span
                     className="
                     bg-blue-300
                     rounded
                     inline-block
                     text-center
                     py-1
                     px-4
                     text-xs
                     leading-loose
                     font-semibold
                     text-white
                     mb-5
                     "
                     >
                  {moment(post.publishedAt).format('MMM DD, YYYY')}
                  </span>
                  <h3>
                     <a
                        href={`/blog/${post.categories.title}/${post.title}`}
                        className="
                        font-bold
                        text-base
                        inline-block
                        text-dark
                        "
                        >
                     {post.title}
                     </a>
                  </h3>
               </div>
            </div>
         </div>
         ))}




      </div>
   </div>
</section>
);

}
