import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function MetaDecorator({ title, description, canonical, keyword, questions = '' }) {
  var structuredDataQuestions = []

  if (questions.length > 0) {
    questions.map(({ title, content }) => (
      structuredDataQuestions.push(
        {
          "@type": "Question",
          "name": title,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>" + content + "</p>"
          }
        }
      )
    ))
  }
  return (
    <HelmetProvider>
      <Helmet>
        <title>{`${title} | drquickpayhk 快清數博士 Doctor Credit`}</title>
        <meta name='description' content={description} />
        <meta name="keywords" content={keyword} />
        <link rel="canonical" href={canonical} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${title} | drquickpayhk 快清數博士 Doctor Credit`} />
        <meta property="og:site_name" content="drquickpayhk 快清數博士 Doctor Credit"/>
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://www.drquickpayhk.com/" />
        <meta property="og:image" content="https://www.drquickpayhk.com/images/logo.png"></meta>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Organization",
                "@id": "https://www.drquickpayhk.com/",
                "name": "drquickpayhk",
                "url": "https://www.drquickpayhk.com/",
                "logo": {
                  "@type": "ImageObject",
                  "@id": "https://www.drquickpayhk.com/#logo",
                  "inLanguage": "zh-HK",
                  "url": "https://www.drquickpayhk.com/logo192.png",
                  "contentUrl": "https://www.drquickpayhk.com/logo192.png",
                  "width": 192,
                  "height": 192,
                  "caption": "drquickpayhk"
                }
              },
              {
                "@type": "WebSite",
                "@id": "https://www.drquickpayhk.com/",
                "url": "https://www.drquickpayhk.com/",
                "name": "drquickpayhk 快清數博士 Doctor Credit",
                "description": {description},
                "publisher": {
                  "@id": "https://www.drquickpayhk.com"
                },
                "inLanguage": "zh-HK"
              },
            ]
          })}
        </script>
        <script type="application/ld+json">
          {(structuredDataQuestions != '') ?
            JSON.stringify({
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                structuredDataQuestions
              ]
            }) : ''}
        </script>
      </Helmet>
    </HelmetProvider>
  )
}
