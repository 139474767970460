import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/home";
import Blog from "./pages/blog";
import BlogDetail from "./pages/blogdetail"
import WOW from 'wowjs'
import '../node_modules/wowjs/css/libs/animate.css';

function App() {

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  });
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<Blog />} />
        <Route path="/blog/:category/" element={<Blog />} />
        <Route path="/blog/:category/:slug" element={<BlogDetail />} />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
