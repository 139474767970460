import React from 'react'
import logoFooter from '../images/logo_footer.webp'
import SocialMediaShare from './SocialMediaShare'

export default function Footer() {
  return (

    <footer className='bg-gray-700'>
      <div className='section-container py-[20px]'>
        <div className='flex flex-col md:flex-row justify-between items-center'>
          <div className='flex items-center'>
            <a href="/">
              <div className="text-5xl ml-4 text-white w-24 h-[48px] flex justify-center items-center">
                <img alt="drquickpayhk" className='mt-4' src={logoFooter} />
              </div>
            </a>

            <ul className="flex flex-wrap ml-[20px] lg:ml-[50px]">
              <li className="px-4 mt-2">
                <a href="/blog/一線清數" className='text-white text-xl hover:opacity-100'>一線清數</a>
              </li>
              <li className="px-4 mt-2">
                <a href="/blog/二線清數" className='text-white text-xl hover:opacity-100'>二線清數</a>
              </li>
              <li className="px-4 mt-2">
                <a href="/blog/小額貸款" className='text-white text-xl hover:opacity-100'>小額貸款</a>
              </li>
              <li className="px-4 mt-2">
                <a href="/blog/現金周轉" className='text-white text-xl hover:opacity-100'>現金周轉</a>
              </li>
              <li className="px-4 mt-2">
                <a href="/blog/成功個案" className='text-white text-xl hover:opacity-100'>成功個案</a>
              </li>

            </ul>
          </div>
          <div className="flex mt-[20px] md:mt-0">

          </div>
        </div>

        <div className='text-center mt-[20px]'>
          <div class="fb-page" data-href="https://www.facebook.com/drquickpayhk" data-tabs="" data-width="" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/drquickpayhk" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/drquickpayhk">快清數博士 Doctor QuickPay</a></blockquote></div>
          <br/>
          <span className='text-white font-bold text-center'>drquickpayhk.com © 2024</span>
        </div>
      </div>
      <div className='fixed bottom-8 right-4 flex'>
        <SocialMediaShare size={84} />
      </div>
    </footer>


  )
}
